import { IActionType, IPageModel } from "@package/store";

const notice = {
  Post: {
    NoticeList: "/backapi/notice/list",
    FindNotice: "/backapi/notice/find",
    AddNotice: "/backapi/notice/add",
    UpdateNotice: "/backapi/notice/update",
    DeleteNotice: "/backapi/notice/delete",
    ChangeStatus: "/backapi/notice/changeStatus"
  },
};

export default {
  namespace: "notice",
  actionType: {
    Post: {
      ...notice.Post,
    },
  },
} as IPageModel;
