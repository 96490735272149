const routes = [
  {
    path: "/notice",
    menuName: "公告管理",
    icon: "SoundOutlined",
    //   element: () => import("./"),
    sort: 1,
    // layout: false,
    children: [
      {
        path: "list",
        menuName: "公告列表",
        element: () => import("./app"),
      },
      {
        path: "add_notice",
        menuName: "添加公告",
        menu: false,
        element: () => import("./edit"),
      },
      {
        path: "edit_notice/:id?",
        menuName: "编辑公告",
        menu: false,
        element: () => import("./edit"),
      },
    ],
  },
];

export default routes;
