import React, { useRef } from "react";
import { RemoteTable } from "@package/table";
import {
  useDelTableRecord,
  useLoadTableData,
} from "@src/javascripts/common/useTableCallback";
// import EditSaint from "./edit";
import { Button, Popconfirm, Space } from "antd";
import { RenderIcon } from "@package/icons";
import { useNavigate } from "react-router-dom";

const Saints = () => {
  const _ref = useRef<any>();
  const [table] = RemoteTable.useTable();

  const _columns = [
    {
      title: "编号",
      dataIndex: "saintId",
    },
    {
      dataIndex: "saintName",
      title: "圣人名称",
      filter: true,
    },
    {
      dataIndex: "saintDate",
      title: "时间",
      filter: true,
      type: "date",
    },
    {
      title: "操作",
      render: (data, record) => {
        return (
          <>
            <Space>
              <Button
                type="link"
                icon={<RenderIcon type="EditOutlined"></RenderIcon>}
                onClick={() => {
                  _edit(record);
                }}
              >
                编辑
              </Button>
              <Popconfirm
                title={`确定要删除x圣人【${record.saintName}】?`}
                onConfirm={() =>
                  useDelTableRecord("fl/DeleteLink")(
                    {
                      id: record.linkId,
                    },
                    table.reload
                  )
                }
              >
                <Button
                  type="link"
                  icon={<RenderIcon type="DeleteOutlined"></RenderIcon>}
                  danger
                >
                  删除
                </Button>
              </Popconfirm>
            </Space>
          </>
        );
      },
    },
  ];

  const navigate = useNavigate();

  //新增|编辑圣人信息
  const _edit = (data?: any) => {
    navigate(
      data?.saintId
        ? `/knowledge/edit_saints/${data?.saintId}`
        : "/knowledge/add_saints"
    );
  };

  return (
    <>
      <RemoteTable
        rowKey={"saintId"}
        columns={_columns}
        table={table}
        loadData={useLoadTableData("knowledge/SaintList")}
        actionBar={
          <Button
            type="primary"
            icon={<RenderIcon type="PlusOutlined"></RenderIcon>}
            onClick={() => _edit()}
          >
            添加
          </Button>
        }
      ></RemoteTable>
      {/* <EditSaint ref={_ref} onSave={() => table.reload()}></EditSaint> */}
    </>
  );
};

export default Saints;
