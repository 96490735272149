import React from "react";
import { RemoteTable } from "@package/table";
import { RenderIcon } from "@package/icons";
import { useLoadTableData } from "@src/javascripts/common/useTableCallback";
import { Button } from "antd";

const BibleVerse = () => {
  const [table] = RemoteTable.useTable();
  const _columns = [
    {
      title: "",
    },
  ];
  return (
    <>
      <RemoteTable
        rowKey={"id"}
        loadData={useLoadTableData("")}
        columns={_columns}
        actionBar={
          <Button
            type="primary"
            icon={<RenderIcon type="PlusOutlined"></RenderIcon>}
          >
            新增
          </Button>
        }
      ></RemoteTable>
    </>
  );
};

export default BibleVerse;
