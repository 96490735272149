import React, { useRef } from "react";
import { IFormItemProps, ModalForm } from "@package/form";

const EditTag = (props, ref) => {
	const _ref = useRef<any>();
	const _formItems: Array<IFormItemProps> = [{
		formItemProps: {
			name: "saintsTagId",
			hidden: true
		}
	}, {
		formItemProps: {
			name: "tagName",
			label: "名称",
			rules: [{ required: true }]
		},
		formControlProps: {
			maxLength: 10
		}
	}];

	const _saveData = async () => {
		return ""
	}

	return <ModalForm ref={_ref} formItems={_formItems} title="添加/编辑标签" onOk={_saveData}></ModalForm>
};

export default React.forwardRef(EditTag);