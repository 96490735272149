import React, { useRef } from "react";
import { RemoteTable } from "@package/table";
import {
  useDelTableRecord,
  useLoadTableData,
} from "@src/javascripts/common/useTableCallback";
// import EditSaint from "./edit";
import { Button, Popconfirm, Space, Tag, Tooltip, message } from "antd";
import { RenderIcon } from "@package/icons";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useDispatchAsync } from "@src/javascripts/common/useDispatch";
import { ResponseCode } from "@src/global/layout";

const Notice = () => {
  const _ref = useRef<any>();
  const [table] = RemoteTable.useTable();

  const _columns = [
    // {
    //   title: "编号",
    //   width: 60,
    //   dataIndex: "noticeId",
    // },
    {
      dataIndex: "noticeTitle",
      title: "通知标题",
      filter: true,
    },
    // {
    //   dataIndex: "noticeContent",
    //   title: "通知内容",
    //   ellipsis: {
    //     showTitle: false
    //   },
    //   render: (data) => {
    //     return <Tooltip placement="topLeft" title={<div dangerouslySetInnerHTML={{ __html: data }} style={{ width: "auto", padding: "10px", maxWidth: "none" }}></div>}>
    //       <div dangerouslySetInnerHTML={{ __html: data }} style={{ maxHeight: "50px" }}></div>
    //     </Tooltip>
    //   }
    // },
    {
      dataIndex: "noticeAlways",
      title: "长期有效",
      width: 80,
      render: (data) => {
        return <>{data ? "是" : "否"}</>;
      },
    },
    {
      dataIndex: "noticeStartDate",
      title: "生效时间",
      width: 140,
      // filter: true,
      type: "date",
      render: (data) => {
        return <>{!!data ? dayjs(data).format("YYYY年MM月DD日") : "----"}</>;
      },
    },
    {
      dataIndex: "noticeEndDate",
      title: "过期时间",
      width: 140,
      // filter: true,
      type: "date",
      render: (data) => {
        return <>{!!data ? dayjs(data).format("YYYY年MM月DD日") : "----"}</>;
      },
    },
    {
      dataIndex: "noticeStatus",
      width: 80,
      title: "状态",
      render: (data, record) => {
        if (data === 0) return <Tag color="blue">未发布</Tag>;

        if (record.noticeStartDate && record.noticeEndDate) {
          console.log(record.noticeStartDate,dayjs(record.noticeStartDate).diff(dayjs(),'day'),dayjs(record.noticeEndDate).diff(dayjs(),'day'))
          if (dayjs(record.noticeStartDate).diff(dayjs(),'day') > 0 || dayjs(record.noticeEndDate).diff(dayjs(),'day') < 0) {
            return <Tag color="red">已过期</Tag>;
          }
          // else {
          //   return <Tag color="blue"> 未生效</Tag>
          // }
        }

        return <Tag color="green">已发布</Tag>
      }
    },
    {
      title: "操作",
      render: (data, record) => {
        return (
          <>
            <Space>
              <Button type="link" danger={!!record.noticeStatus} icon={<RenderIcon type="SendOutlined"></RenderIcon>} onClick={() => _switchStatus(record)}>{
                record.noticeStatus ? "下线" : "发布"
              }</Button>
              <Button
                type="link"
                icon={<RenderIcon type="EditOutlined"></RenderIcon>}
                onClick={() => {
                  _edit(record);
                }}
              >
                编辑
              </Button>
              <Popconfirm
                title={`确定要删除通知【${record.noticeTitle}】?`}
                onConfirm={() =>
                  useDelTableRecord("notice/DeleteNotice")(
                    {
                      id: record.noticeId,
                    },
                    table.reload
                  )
                }
              >
                <Button
                  type="link"
                  icon={<RenderIcon type="DeleteOutlined"></RenderIcon>}
                  danger
                >
                  删除
                </Button>
              </Popconfirm>
            </Space>
          </>
        );
      },
    },
  ];

  const navigate = useNavigate();

  //新增|编辑圣人信息
  const _edit = (data?: any) => {
    navigate(
      data?.noticeId
        ? `/notice/edit_notice/${data?.noticeId}`
        : "/notice/add_notice"
    );
  };

  const _switchStatus = async (data?: any) => {
    const _res = await useDispatchAsync({
      type: "notice/ChangeStatus",
      data: {
        noticeId: data.noticeId,
        noticeStatus: !data.noticeStatus
      }
    });

    if (_res.code === ResponseCode.Success) {
      message.success("状态修改成功")
      table.reload()
      return;
    }

    message.error(_res.message);
  }

  return (
    <>
      <RemoteTable
        rowKey={"noticeId"}
        columns={_columns}
        table={table}
        loadData={useLoadTableData("notice/NoticeList")}
        scroll={{ x: true }}
        expandable={{
          expandedRowRender: (record) => {
            return <>
              {/* <Tooltip placement="topLeft" title={<div dangerouslySetInnerHTML={{ __html: record.noticeContent }} style={{ width: "auto", padding: "10px", maxWidth: "none" }}></div>}> */}
              <div dangerouslySetInnerHTML={{ __html: record.noticeContent }} style={{ lineHeight: "1.25" }}></div>
              {/* </Tooltip> */}
            </>
          }
        }}
        actionBar={
          <Button
            type="primary"
            icon={<RenderIcon type="PlusOutlined"></RenderIcon>}
            onClick={() => _edit()}
          >
            添加
          </Button>
        }
      ></RemoteTable>
      {/* <EditSaint ref={_ref} onSave={() => table.reload()}></EditSaint> */}
    </>
  );
};

export default Notice;
