import { IActionType, IPageModel } from "@package/store";

const saints = {
  Post: {
    SaintList: "/backapi/saints/list",
    FindSaint: "/backapi/saints/find",
    AddSaint: "/backapi/saints/add",
    UpdateSaint: "/backapi/saints/update",
    DeleteSaint: "/backapi/saints/delete",
  },
};

export default {
  namespace: "knowledge",
  actionType: {
    Post: {
      ...saints.Post,
    },
  },
} as IPageModel;
