const routes = [
  {
    path: "/knowledge",
    menuName: "圣教天地",
    icon: "NewsOutlined",
    // element: () => import("./app"),
    sort: 2,
    menu: false,
    children: [
      {
        path: "bible",
        menuName: "圣经",
        index: true,
        element: () => import("./bible"),
      },
      {
        path: "religions",
        menuName: "教仪",
        element: () => import("./religions"),
      },
      {
        path: "etiquette",
        menuName: "礼仪",
        element: () => import("./etiquette"),
      },
      {
        path: "spiritual",
        menuName: "灵修",
        element: () => import("./spiritual"),
      },
      {
        path: "theology",
        menuName: "神学",
        element: () => import("./theology"),
      },
      {
        path: "literature",
        menuName: "教会文献",
        element: () => import("./literature"),
      },
      {
        path: "verse",
        menuName: "圣经金句",
        element: () => import("./bibleverse"),
      }
    ]
  },
];

export default routes;
