const routes = [
  {
    path: "/saints",
    menuName: "圣人",
    icon: "UserOutlined",
    // element: () => import("./app"),
    sort: 2,
    menu: true,
    children: [
      {
        path: "list",
        menuName: "圣人列表",
        element: () => import("./saintList"),
      },
      {
        path: "add_saints",
        menuName: "新增",
        menu: false,
        element: () => import("./saintList/edit"),
      },
      {
        path: "edit_saints/:id?",
        menuName: "修改",
        menu: false,
        element: () => import("./saintList/edit"),
      },
      {
        path: "tags",
        menuName: "标签列表",
        element: () => import("./saintsTag"),
      },
    ],
  },
];

export default routes;
