import { IActionType, IPageModel } from "@package/store";

const saints = {
  Post: {
    SaintList: "/backapi/saints/list",
    FindSaint: "/backapi/saints/find",
    AddSaint: "/backapi/saints/add",
    DeleteSaint: "/backapi/saints/delete",
    UpdateSaint: "/backapi/saints/update",
  },
  Get: {
    RetriveData: "/backapi/saints/retrive",
  },
};

const saintsTag = {
  Post: {
    SaintTagList: "/backapi/saints_tag/list",
    FindSaintTag: "/backapi/saints_tag/find",
    AddSaintTag: "/backapi/saints_tag/add",
    UpdateSaintTag: "/backapi/saints_tag/update",
  },
  Get: {
    DeleteSaintTag: "/backapi/saints_tag/del",
    QueryAllSaintsTag: "/backapi/saints_tag/query",
  },
};
export default {
  namespace: "saints",
  actionType: {
    Post: {
      ...saints.Post,
      ...saintsTag.Post,
    },
    Get: {
      ...saints.Get,
      ...saintsTag.Get,
    },
  },
} as IPageModel;
