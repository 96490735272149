import React, { useImperativeHandle, useRef } from "react";
import { IFormItemProps, ModalForm } from "@package/form";
import { useDispatchAsync } from "@src/javascripts/common/useDispatch";
import { ResponseCode } from "@src/global/layout";
import { message } from "antd";

const EditTag = (props, ref) => {
  const _ref = useRef<any>();
  const _formItems: Array<IFormItemProps> = [
    {
      formItemProps: {
        name: "saintsTagId",
        hidden: true,
      },
    },
    {
      formItemProps: {
        name: "saintsTagName",
        label: "名称",
        rules: [{ required: true }],
      },
      formControlProps: {
        maxLength: 10,
      },
    },
    {
      type: "switch",
      formItemProps: {
        name: "saintsTagOnTop",
        label: "置顶",
        initialValue: false,
      },
      formControlProps: {
        checkedChildren: "是",
        unCheckedChildren: "否",
      },
    },
  ];

  const _saveData = async (data) => {
    const _res = await useDispatchAsync({
      type: !data.saintsTagId ? "saints/AddSaintTag" : "saints/UpdateSaintTag",
      data: data,
    });

    if (_res.code === ResponseCode.Success) {
      message.success("保存成功");
      props.onSave?.();
      return;
    }

    return _res.message;
  };

  useImperativeHandle(ref, () => _ref.current, []);

  return (
    <ModalForm
      ref={_ref}
      formItems={_formItems}
      title="添加/编辑标签"
      onOk={_saveData}
    ></ModalForm>
  );
};

export default React.forwardRef(EditTag);
