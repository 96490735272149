import token from "@src/javascripts/common/storage/token";
import { RenderIcon } from "@package/icons";
import { Form, Checkbox, Input, Button, message } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ResponseCode } from "@src/global/layout";
import { useLocalDispatch } from "@src/javascripts/common/useDispatch";

const AccountLogin = () => {
  const [form] = Form.useForm();
  const [isChecked, setChecked] = useState(false);
  const navigate = useNavigate();

  const login = async () => {
    await form.validateFields();

    const res = await window.$store.dispatchAsync({
      type: "login/Login",
      data: form.getFieldsValue(),
    });

    if (res.code === ResponseCode.Success) {
      token.save(res.data.access_token);
      navigate("/");
      setTimeout(() => {
        useLocalDispatch({
          type: "global/UserProfile",
        });
      });
      return;
    }

    // if (res.code != 0) {
    message.error(res.message);
    return;
    // }
  };

  const chkChange = (evt) => {
    setChecked(evt.target.checked);
  };

  const keyDown = (evt) => {
    if (evt.keyCode === 13) {
      login();
    }
  };

  return (
    <div className="account-login" style={{ marginTop: "-20px" }}>
      <Form form={form} autoComplete={"off"}>
        <Form.Item
          name={"username"}
          rules={[{ required: true, message: "账号不能为空" }]}
        >
          <Input
            prefix={<RenderIcon type="UserOutlined" />}
            placeholder="账号"
            allowClear={true}
            onKeyDown={keyDown}
          ></Input>
        </Form.Item>
        <Form.Item
          name={"password"}
          rules={[{ required: true, message: "密码不能为空" }]}
        >
          <Input.Password
            prefix={<RenderIcon type="LockOutlined" />}
            placeholder="密码"
            allowClear={true}
            onKeyDown={keyDown}
          ></Input.Password>
        </Form.Item>
      </Form>
      <div className="field-item" style={{ marginTop: "60px" }}>
        <Button type="primary" onClick={login}>
          登录
        </Button>
      </div>
    </div>
  );
};

export default AccountLogin;
