import React, { useRef } from "react";
import { RemoteTable } from "@package/table";
import {
  useDelTableRecord,
  useLoadTableData,
} from "@src/javascripts/common/useTableCallback";
import { Button, Popconfirm, Space, Switch } from "antd";
import { RenderIcon } from "@package/icons";
import Edit from "./edit";

const SaintsTags = () => {
  const _editRef = useRef<any>();
  const [table] = RemoteTable.useTable();

  const _columns = [
    // {
    //   title: "编号",
    //   dataIndex: "saintsTagId",
    // },
    {
      title: "名称",
      dataIndex: "saintsTagName",
      filter: true,
    },
    {
      title: "是否置顶",
      dataIndex: "saintsTagOnTop",
      render: (data) => {
        return (
          <Switch
            checkedChildren="是"
            unCheckedChildren="否"
            checked={data}
          ></Switch>
        );
      },
    },
    {
      title: "操作",
      render: (data, record) => {
        return (
          <Space>
            <Button
              type="link"
              onClick={() => _editForm(record)}
              icon={<RenderIcon type="EditOutlined"></RenderIcon>}
            >
              编辑
            </Button>
            <Popconfirm
              title={`确定要删除这个标签？`}
              onConfirm={useDelTableRecord("saints/")}
              icon={<RenderIcon type="DeleteOutlined"></RenderIcon>}
            >
              <Button type="link" danger>
                删除
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const _editForm = (data?: any) => {
    _editRef.current.setVisible(true);

    if (!!data) {
      _editRef.current.setFieldsValue(data);
    }
  };

  return (
    <>
      <RemoteTable
        table={table}
        columns={_columns}
        rowKey={"saintsTagId"}
        loadData={useLoadTableData("saints/SaintTagList")}
        actionBar={
          <Button
            type="primary"
            onClick={() => _editForm()}
            icon={<RenderIcon type="PlusOutlined"></RenderIcon>}
          >
            添加
          </Button>
        }
      ></RemoteTable>
      <Edit ref={_editRef} onSave={() => table.reload()}></Edit>
    </>
  );
};

export default SaintsTags;
